import React from 'react';
import styled from "styled-components";

export const Seal = () => {
    return (
        <Wrap>
            <svg width="192" height="192" viewBox="0 0 192 192">

<path d="M96 1.97938C44.0738 1.97938 1.97938 44.0738 1.97938 96C1.97938 147.926 44.0738 190.021 96 190.021C147.926 190.021 190.021 147.926 190.021 96C190.021 44.0738 147.926 1.97938 96 1.97938ZM0 96C0 42.9807 42.9807 0 96 0C149.019 0 192 42.9807 192 96C192 149.019 149.019 192 96 192C42.9807 192 0 149.019 0 96Z"/>
<path d="M118.712 16.1091L121.148 16.8704C122.846 17.3938 123.719 18.4961 123.227 20.0822L123.211 20.1218C122.711 21.7237 121.338 22.0727 119.72 21.5651L118.664 21.2321L117.768 24.1107L116.355 23.6746L118.712 16.1091ZM120.005 20.5421C120.934 20.8355 121.576 20.5342 121.838 19.6936L121.854 19.6539C122.132 18.7578 121.711 18.2424 120.815 17.9569L119.791 17.6397L118.99 20.2249L120.005 20.5421Z"/>
<path d="M126.766 18.7975L129.21 19.8522C130.805 20.5421 131.646 21.6762 131.003 23.1591L130.987 23.1988C130.495 24.3407 129.535 24.6103 128.528 24.4517L129.218 28.4803L127.75 27.8459L127.115 24.0869L126.25 23.7142L124.981 26.6563L123.624 26.0695L126.766 18.7975ZM127.75 23.2226C128.655 23.6111 129.305 23.4367 129.638 22.6595L129.654 22.6199C130.011 21.7872 129.623 21.2796 128.75 20.899L127.663 20.4311L126.663 22.7547L127.75 23.2226Z"/>
<path d="M132.559 25.7602L132.607 25.6809C133.773 23.6745 136.074 23.0243 138.105 24.2059C140.145 25.3954 140.716 27.6555 139.549 29.6698L139.502 29.7491C138.335 31.7554 136.09 32.4612 134.011 31.2558C131.908 30.0346 131.416 27.7269 132.559 25.7602ZM138.169 28.9878L138.216 28.9085C139.034 27.4969 138.859 25.9981 137.51 25.2209C136.169 24.4438 134.797 24.9672 133.94 26.4422L133.892 26.5215C133.027 28.0124 133.368 29.5112 134.622 30.2328C135.923 30.9862 137.312 30.4707 138.169 28.9878Z"/>
<path d="M144.143 28.1313L146.063 29.5826C148.261 31.2479 148.38 33.3098 146.992 35.1496L146.928 35.2289C145.54 37.0687 143.477 37.5604 141.255 35.8871L139.351 34.4517L144.143 28.1313ZM141.929 34.9355C143.342 36.0061 144.643 35.7047 145.698 34.309L145.754 34.2376C146.785 32.8736 146.785 31.5889 145.302 30.4628L144.619 29.9474L141.239 34.4121L141.929 34.9355Z"/>
<path d="M148.301 37.6476L151.705 34.1028L152.776 35.1337L149.451 38.5992C148.443 39.646 148.364 40.3994 149.213 41.2162C150.038 42.0092 150.808 42.0409 151.943 40.8514L155.196 37.4573L156.267 38.4882L152.927 41.9696C151.49 43.4684 149.895 43.516 148.38 42.0647C146.952 40.6849 146.888 39.1226 148.301 37.6476Z"/>
<path d="M155.33 43.3336L155.402 43.2781C157.243 41.7634 159.575 41.9776 160.972 43.6826C162.122 45.0862 162.313 46.7516 160.988 48.068L160.036 46.9102C160.742 46.1171 160.789 45.3162 160.067 44.4359C159.179 43.3574 157.766 43.3416 156.394 44.4676L156.322 44.5232C154.942 45.6572 154.672 46.9815 155.6 48.1156C156.298 48.9641 157.219 49.2099 158.179 48.6231L159.091 49.7333C157.473 50.7642 155.862 50.3281 154.672 48.8769C153.101 46.9577 153.442 44.88 155.33 43.3336Z"/>
<path d="M164.647 50.8118L163.441 48.964L164.417 48.3296L167.646 53.2622L166.67 53.8966L165.464 52.0489L159.807 55.7523L158.989 54.5072L164.647 50.8118Z"/>
<path d="M171.049 59.4557L172.04 61.6524C173.175 64.1662 172.286 66.0378 170.184 66.9815L170.096 67.0211C167.994 67.9648 165.947 67.4176 164.804 64.88L163.828 62.7071L171.049 59.4557ZM165.844 64.3645C166.574 65.9823 167.851 66.3391 169.446 65.6175L169.525 65.5778C171.08 64.88 171.707 63.7459 170.938 62.0489L170.588 61.2717L165.486 63.5715L165.844 64.3645Z"/>
<path d="M174.81 68.3772L176.342 73.0877L175.231 73.4446L174.152 70.1377L172.089 70.8118L172.954 73.4684L171.898 73.8094L171.034 71.1528L168.828 71.8665L169.962 75.3558L168.852 75.7127L167.265 70.8197L174.81 68.3772Z"/>
<path d="M171.74 76.8864L172.041 78.2742C171.28 78.5359 170.7 78.9959 170.986 80.3044C171.169 81.1608 171.779 81.6604 172.494 81.5097C173.208 81.3591 173.406 80.9388 173.335 79.6382C173.184 77.735 173.557 76.7357 175.009 76.4185C176.278 76.1489 177.461 77.0212 177.81 78.639C178.167 80.3043 177.627 81.4701 176.175 81.938L175.89 80.6136C176.675 80.2964 176.921 79.7572 176.723 78.869C176.532 77.9887 176.017 77.6319 175.414 77.7587C174.787 77.8936 174.525 78.2187 174.62 79.6065C174.763 81.478 174.446 82.5327 172.898 82.8658C171.557 83.1592 170.256 82.2473 169.891 80.5343C169.439 78.4249 170.304 77.3067 171.74 76.8864Z"/>
<path d="M178.808 84.7927L178.999 86.2677L171.136 87.2907L170.945 85.8157L178.808 84.7927Z"/>
<path d="M175.389 89.5509H175.477C177.833 89.4637 179.579 91.0418 179.666 93.3495C179.729 95.0466 179.007 96.5771 177.119 96.8547L177.063 95.3796C178.111 95.1655 178.539 94.436 178.5 93.405C178.444 91.8586 177.254 91.0022 175.516 91.0656H175.429C173.731 91.129 172.596 91.9617 172.66 93.6191C172.715 95.1021 173.636 95.681 174.731 95.681L174.659 93.6905L175.77 93.6509L175.897 97.0926L175.167 97.1243C172.977 97.2036 171.596 95.8793 171.509 93.6429C171.422 91.1211 173.049 89.6382 175.389 89.5509Z"/>
<path d="M179.579 100.185L179.436 101.938L173.446 104.785L179.158 105.261L179.047 106.601L171.145 105.943L171.271 104.42L177.627 101.367L171.573 100.859L171.684 99.5193L179.579 100.185Z"/>
<path d="M178.499 110.13L177.547 114.991L176.405 114.769L177.071 111.351L174.937 110.931L174.397 113.675L173.31 113.461L173.85 110.717L171.572 110.273L170.866 113.873L169.724 113.651L170.715 108.607L178.499 110.13Z"/>
<path d="M176.77 118.155L175.992 120.701C175.485 122.366 174.445 123.326 172.898 122.85L172.858 122.834C171.668 122.469 171.295 121.541 171.335 120.526L167.407 121.652L167.875 120.13L171.549 119.083L171.827 118.179L168.764 117.243L169.193 115.832L176.77 118.155ZM172.477 119.63C172.192 120.574 172.438 121.193 173.247 121.438L173.287 121.454C174.16 121.724 174.62 121.272 174.897 120.368L175.247 119.234L172.827 118.496L172.477 119.63Z"/>
<path d="M168.375 128.179C169.081 128.504 169.407 129.329 169.065 130.066C168.716 130.812 167.883 131.082 167.177 130.756C166.463 130.423 166.138 129.614 166.487 128.869C166.828 128.139 167.661 127.854 168.375 128.179Z"/>
<path d="M164.749 136.545L164.821 136.593C166.82 137.894 167.312 140.177 166.106 142.025C165.114 143.548 163.583 144.23 161.924 143.365L162.742 142.112C163.718 142.549 164.487 142.35 165.114 141.399C165.876 140.233 165.463 138.877 163.979 137.909L163.908 137.862C162.416 136.886 161.067 137.029 160.266 138.25C159.663 139.17 159.703 140.122 160.56 140.859L159.774 142.065C158.306 140.828 158.235 139.17 159.266 137.592C160.623 135.507 162.71 135.213 164.749 136.545Z"/>
<path d="M161.059 148.75L159.83 150.233L152.054 147.188L153.03 146.014L154.942 146.783L156.878 144.452L155.767 142.723L156.656 141.661L161.059 148.75ZM157.489 145.396L155.997 147.196L159.576 148.647L157.489 145.396Z"/>
<path d="M152.76 150.011L151.744 151.002C151.117 150.503 150.419 150.249 149.459 151.177C148.832 151.787 148.729 152.565 149.237 153.096C149.745 153.619 150.205 153.564 151.268 152.818C152.808 151.684 153.855 151.47 154.886 152.533C155.791 153.461 155.672 154.936 154.49 156.086C153.268 157.275 151.99 157.426 150.824 156.45L151.792 155.507C152.474 155.998 153.061 155.927 153.72 155.293C154.363 154.666 154.394 154.032 153.974 153.595C153.53 153.135 153.109 153.088 151.982 153.905C150.467 155.023 149.404 155.308 148.301 154.174C147.341 153.191 147.428 151.605 148.689 150.384C150.237 148.877 151.633 149.012 152.76 150.011Z"/>
<path d="M149.801 160.233L145.913 163.31L145.191 162.398L147.921 160.233L146.572 158.528L144.382 160.265L143.691 159.392L145.881 157.656L144.437 155.84L141.557 158.116L140.835 157.204L144.866 154.008L149.801 160.233Z"/>
<path d="M137.502 162.453L136.272 163.159C135.788 162.517 135.177 162.097 134.011 162.763C133.249 163.199 132.956 163.928 133.313 164.563C133.678 165.197 134.13 165.261 135.352 164.801C137.129 164.079 138.192 164.143 138.93 165.427C139.573 166.553 139.097 167.949 137.661 168.766C136.177 169.614 134.908 169.44 134.019 168.203L135.193 167.537C135.733 168.187 136.32 168.258 137.113 167.806C137.891 167.362 138.081 166.752 137.78 166.228C137.462 165.673 137.074 165.522 135.772 166.03C134.027 166.728 132.924 166.744 132.138 165.372C131.456 164.174 131.932 162.668 133.455 161.795C135.336 160.725 136.661 161.208 137.502 162.453Z"/>
<path d="M130.377 171.097L132.392 170.201L132.868 171.264L127.481 173.667L127.005 172.604L129.02 171.708L126.267 165.53L127.623 164.928L130.377 171.097Z"/>
<path d="M123.283 170.027L124.783 174.698L123.37 175.15L121.902 170.582C121.458 169.202 120.831 168.774 119.712 169.131C118.625 169.48 118.229 170.138 118.736 171.7L120.173 176.173L118.76 176.625L117.284 172.033C116.65 170.059 117.364 168.631 119.363 167.989C121.252 167.394 122.656 168.084 123.283 170.027Z"/>
<path d="M115.309 177.505L112.944 177.965C110.231 178.496 108.62 177.204 108.175 174.944L108.16 174.848C107.715 172.588 108.723 170.717 111.452 170.185L113.793 169.733L115.309 177.505ZM111.714 171.319C109.977 171.66 109.326 172.818 109.667 174.539L109.683 174.626C110.008 176.3 110.968 177.164 112.793 176.807L113.634 176.641L112.563 171.145L111.714 171.319Z"/>
<path d="M105.811 179.099L104.335 179.25L103.51 171.367L104.986 171.216L105.811 179.099Z"/>
<path d="M100.852 179.543L95.8929 179.67L95.8612 178.504L99.3445 178.417L99.289 176.244L96.496 176.316L96.4642 175.205L99.2572 175.134L99.1937 172.818L95.52 172.913L95.4883 171.748L100.638 171.613L100.852 179.543Z"/>
<path d="M93.5441 174.174L92.1317 174.071C92.0921 173.27 91.8223 172.58 90.4813 172.485C89.6085 172.422 88.95 172.858 88.9024 173.588C88.8468 174.317 89.1959 174.618 90.4575 174.928C92.3301 175.324 93.1791 175.967 93.068 177.442C92.9728 178.734 91.7905 179.622 90.1481 179.504C88.4421 179.377 87.49 178.528 87.4424 177.005L88.7913 177.109C88.8706 177.949 89.3229 178.338 90.2274 178.401C91.124 178.465 91.616 178.068 91.6636 177.465C91.7112 176.823 91.4732 176.482 90.1163 176.181C88.2755 175.784 87.3551 175.189 87.4741 173.603C87.5773 172.231 88.8151 171.248 90.5607 171.375C92.7189 171.542 93.5441 172.676 93.5441 174.174Z"/>
<path d="M81.2373 173.667C81.0786 174.428 80.3487 174.928 79.5552 174.761C78.7538 174.595 78.3015 173.841 78.4602 173.08C78.6269 172.311 79.341 171.819 80.1424 171.986C80.9358 172.152 81.404 172.89 81.2373 173.667Z"/>
<path d="M70.5741 175.61L65.9482 173.976L66.337 172.866L69.5744 174.008L70.3599 171.787L67.8129 170.883L68.1937 169.805L70.7407 170.709L71.8198 167.648L73.2084 168.139L70.5741 175.61Z"/>
<path d="M63.1619 172.826L58.6709 170.725L59.1628 169.67L62.3208 171.145L63.2412 169.178L60.7101 167.997L61.1782 166.99L63.7094 168.171L64.6933 166.07L61.3687 164.515L61.8606 163.461L66.5261 165.642L63.1619 172.826Z"/>
<path d="M55.8073 169.21L53.3713 167.751C51.9748 166.91 51.467 165.887 52.2049 164.65L52.2287 164.611C52.6334 163.936 53.2602 163.588 54.1727 163.81C53.3713 163.056 53.2364 162.287 53.776 161.391L53.7998 161.351C54.5615 160.082 55.7755 159.948 57.2751 160.844L59.8936 162.414L55.8073 169.21ZM55.236 164.975C54.3949 164.476 53.895 164.587 53.4745 165.277L53.4507 165.316C53.0619 165.967 53.2682 166.458 54.0775 166.942L55.109 167.561L56.2833 165.602L55.236 164.975ZM56.8784 161.835C56.0294 161.327 55.4105 161.47 54.99 162.176L54.9662 162.216C54.5456 162.921 54.7202 163.508 55.6565 164.063L56.7991 164.745L58.1083 162.565L56.8784 161.835Z"/>
<path d="M48.277 164.476L46.1505 162.874C44.762 161.827 44.2145 160.526 45.1905 159.234L45.2143 159.202C45.9601 158.211 46.9599 158.179 47.9041 158.568L48.1897 154.492L49.4672 155.451L49.1895 159.258L49.9433 159.829L51.8714 157.275L53.0457 158.163L48.277 164.476ZM48.3643 159.948C47.5788 159.353 46.9043 159.377 46.3965 160.051L46.3727 160.082C45.8252 160.804 46.0871 161.399 46.8409 161.962L47.7851 162.676L49.3085 160.661L48.3643 159.948Z"/>
<path d="M44.6902 155.443L41.3101 159.012L40.231 157.989L43.5318 154.507C44.5315 153.453 44.6029 152.699 43.7539 151.89C42.9208 151.105 42.1511 151.082 41.0244 152.271L37.7871 155.681L36.708 154.658L40.0247 151.161C41.4529 149.654 43.0478 149.599 44.5712 151.034C46.0232 152.398 46.0946 153.96 44.6902 155.443Z"/>
<path d="M33.2174 150.772L31.9717 149.297L36.3436 142.184L37.3275 143.35L36.2326 145.102L38.1845 147.418L40.0888 146.625L40.9854 147.679L33.2174 150.772ZM37.145 147.854L35.6375 146.07L33.5745 149.337L37.145 147.854Z"/>
<path d="M28.5365 144.904L27.0528 142.691C26.0847 141.248 25.9975 139.836 27.3384 138.94L27.3781 138.917C28.4096 138.227 29.3697 138.52 30.1314 139.194L31.7342 135.435L32.6229 136.76L31.1153 140.265L31.639 141.05L34.305 139.265L35.1302 140.487L28.5365 144.904ZM30.0997 140.645C29.5522 139.829 28.9095 139.63 28.2112 140.098L28.1715 140.122C27.4178 140.629 27.4733 141.272 27.997 142.049L28.6556 143.032L30.7582 141.629L30.0997 140.645Z"/>
<path d="M26.9177 133.802L24.1803 138.227L23.4186 136.791L25.4816 133.683H21.7682L21.0938 132.414L26.2354 132.501L29.2584 130.899L29.9567 132.208L26.9177 133.802Z"/>
<path d="M25.8456 124.143L21.8704 122.358C20.8706 121.906 20.0613 121.708 19.3472 121.962C18.6489 122.208 18.3474 122.755 18.5934 123.484C18.8552 124.23 19.4503 124.547 20.5453 124.309L20.9897 125.586C19.3234 126.062 18.038 125.404 17.4826 123.825C16.943 122.279 17.5064 121.042 18.8314 120.582C19.8074 120.241 20.8548 120.519 22.0212 121.113L24.227 122.208L23.1161 119.028L24.1952 118.655L26.0837 124.063L25.8456 124.143Z"/>
<path d="M19.9818 117.672L19.593 117.759C17.2523 118.306 15.5146 117.346 15.0544 115.403C14.6021 113.453 15.7288 111.843 18.0457 111.304L18.4345 111.216C20.7594 110.677 22.5208 111.541 22.989 113.556C23.4571 115.514 22.3066 117.124 19.9818 117.672ZM18.7757 112.644L18.3949 112.731C16.7524 113.112 15.9034 113.968 16.1732 115.126C16.4429 116.292 17.522 116.712 19.2359 116.315L19.6247 116.228C21.3783 115.824 22.1003 114.944 21.8305 113.786C21.5687 112.596 20.5213 112.239 18.7757 112.644Z"/>
<path d="M21.5934 108.758L18.0942 106.173C17.2135 105.522 16.4597 105.158 15.7138 105.245C14.9759 105.332 14.5633 105.808 14.6585 106.569C14.7537 107.346 15.2695 107.79 16.3962 107.783L16.5628 109.123C14.8331 109.234 13.7143 108.322 13.508 106.664C13.3096 105.039 14.1269 103.952 15.5075 103.786C16.5311 103.659 17.4991 104.151 18.5147 104.975L20.4349 106.514L20.0223 103.167L21.157 103.024L21.8552 108.71L21.5934 108.758Z"/>
<path d="M14.5155 99.773L15.7216 101.399L14.4282 101.446L13.0555 99.654L13 98.3376L20.9187 98.0442L20.9743 99.5271L14.5155 99.773Z"/>
<path d="M18.0224 87.4098C17.2527 87.3067 16.6973 86.6167 16.8004 85.8158C16.9036 84.999 17.6256 84.4914 18.3953 84.5945C19.1729 84.6976 19.7283 85.3717 19.6172 86.1885C19.5061 86.9895 18.7999 87.5129 18.0224 87.4098Z"/>
<path d="M22.6643 79.4081L21.5456 79.1226C21.7201 78.8847 21.8788 78.6389 21.9978 78.1869C22.1803 77.489 21.9264 76.9418 20.9822 76.696L15.666 75.324L16.0389 73.8887L21.3869 75.2685C23.0531 75.6968 23.5689 76.8467 23.2039 78.2662C23.0373 78.8926 22.8548 79.194 22.6643 79.4081Z"/>
<path d="M21.5778 72.3344L16.9678 70.6453L17.4835 69.2496L21.9904 70.907C23.3551 71.4066 24.0772 71.1766 24.4818 70.0743C24.8786 69.0038 24.5929 68.2821 23.0536 67.7191L18.642 66.1013L19.1577 64.7056L23.6884 66.3709C25.6324 67.0846 26.3227 68.5279 25.6006 70.4946C24.9103 72.3503 23.4979 73.0402 21.5778 72.3344Z"/>
<path d="M25.2516 64.3646L25.8864 63.0957C26.6481 63.3654 27.386 63.3733 27.9811 62.1758C28.3699 61.3907 28.2112 60.6215 27.5606 60.2884C26.9099 59.9554 26.4973 60.1616 25.7277 61.2163C24.6486 62.7944 23.7361 63.3416 22.4031 62.6754C21.2446 62.0965 20.8638 60.6691 21.6096 59.1941C22.3714 57.6715 23.5219 57.1005 24.9501 57.6477L24.3471 58.8531C23.5377 58.6072 23.0061 58.8769 22.6015 59.6937C22.1968 60.4946 22.3793 61.1053 22.9268 61.3749C23.4981 61.6604 23.9107 61.5652 24.7041 60.4312C25.7674 58.8769 26.6719 58.2583 28.0922 58.9641C29.3221 59.5826 29.7585 61.1053 28.9809 62.6675C28.0129 64.6025 26.6402 64.9356 25.2516 64.3646Z"/>
<path d="M26.3936 53.2146L25.2192 55.0782L24.2354 54.4597L27.3775 49.4795L28.3613 50.098L27.187 51.9617L32.8999 55.5699L32.1065 56.8229L26.3936 53.2146Z"/>
<path d="M29.0674 47.1718L29.9561 45.9823L36.3117 50.7246L35.423 51.9141L29.0674 47.1718Z"/>
<path d="M32.1143 43.1671L33.2886 41.8506L39.787 43.1988L35.5182 39.3685L36.4148 38.3693L42.3181 43.6667L41.2946 44.8086L34.3836 43.405L38.9063 47.4652L38.0097 48.4644L32.1143 43.1671Z"/>
<path d="M42.0811 33.0797L44.152 31.4144C45.5088 30.3279 46.9053 30.1138 47.913 31.3747L47.9447 31.4064C48.7223 32.3739 48.5081 33.3493 47.905 34.1661L51.7851 35.4429L50.5393 36.4421L46.9212 35.2447L46.1832 35.8315L48.1907 38.3295L47.0402 39.2494L42.0811 33.0797ZM46.453 34.2772C47.2227 33.6586 47.3655 33.0083 46.8339 32.3501L46.8021 32.3184C46.2308 31.6126 45.5961 31.7236 44.8661 32.3105L43.9457 33.048L45.5326 35.0226L46.453 34.2772Z"/>
<path d="M49.2373 27.5683L50.483 26.7832L52.2763 29.6301L55.2914 27.7348L53.4982 24.8879L54.7518 24.0948L58.9731 30.8038L57.7194 31.5968L55.9262 28.7499L52.911 30.6452L54.7042 33.4922L53.4585 34.2773L49.2373 27.5683Z"/>
<path d="M59.2748 26.1409L59.2351 26.0615C58.2591 23.96 59.0447 21.6999 61.1791 20.7086C63.3214 19.7174 65.5034 20.5421 66.4873 22.6595L66.527 22.7388C67.5029 24.8403 66.7968 27.0845 64.6147 28.0996C62.4089 29.1226 60.2428 28.2186 59.2748 26.1409ZM65.1464 23.397L65.1067 23.3177C64.4164 21.8427 63.0834 21.1289 61.6789 21.7792C60.2666 22.4295 59.9175 23.8569 60.6395 25.4033L60.6792 25.4826C61.4092 27.0528 62.8374 27.6238 64.1466 27.0132C65.4955 26.3867 65.8684 24.9513 65.1464 23.397Z"/>
<path d="M69.3678 17.5445L71.2007 16.9418L76.1598 23.6507L74.7078 24.1266L73.4938 22.4612L70.6215 23.4049L70.6453 25.4588L69.3281 25.8871L69.3678 17.5445ZM70.6135 22.2788L72.8352 21.5492L70.5818 18.4168L70.6135 22.2788Z"/>
<path d="M76.6113 15.3874L78.9679 14.9037C81.6736 14.3486 83.3002 15.6253 83.7604 17.8855L83.7763 17.9806C84.2365 20.2407 83.2526 22.1123 80.5231 22.6674L78.1903 23.1432L76.6113 15.3874ZM80.2533 21.5413C81.991 21.1844 82.6258 20.0187 82.2766 18.3058L82.2608 18.2185C81.9196 16.5453 80.9516 15.6888 79.1266 16.0615L78.2935 16.228L79.4122 21.7078L80.2533 21.5413Z"/>
<path d="M86.1172 13.6824L91.0525 13.2224L91.1636 14.3802L87.6962 14.7054L87.8945 16.8703L90.6796 16.6086L90.7827 17.7109L87.9977 17.9726L88.2119 20.2803L91.8698 19.9393L91.9809 21.0971L86.8551 21.5729L86.1172 13.6824Z"/>
<path d="M93.75 18.5199L95.1703 18.5437C95.2576 19.3446 95.567 20.0108 96.908 20.0345C97.7808 20.0504 98.4155 19.5746 98.4235 18.8371C98.4394 18.1075 98.0744 17.822 96.789 17.592C94.9005 17.3066 94.0039 16.7118 94.0356 15.2288C94.0594 13.9283 95.1782 12.9766 96.8366 13.0004C98.5425 13.0322 99.5502 13.8252 99.6851 15.3399L98.3283 15.3161C98.2013 14.4834 97.7252 14.1186 96.8207 14.1027C95.9241 14.0869 95.448 14.5151 95.4401 15.1257C95.4321 15.7681 95.694 16.0932 97.0587 16.3153C98.9154 16.6008 99.8755 17.1479 99.8517 18.734C99.8279 20.1059 98.6457 21.1686 96.8921 21.1369C94.726 21.1051 93.8293 20.0108 93.75 18.5199Z"/>
<path d="M106.073 18.2344C106.184 17.4651 106.874 16.9179 107.675 17.029C108.485 17.1479 108.993 17.8616 108.881 18.6309C108.77 19.408 108.088 19.9552 107.279 19.8363C106.477 19.7252 105.962 19.0115 106.073 18.2344Z"/>
            </svg>
        </Wrap>
    );
}

const Wrap = styled.div`
    width: 192px;
    height: 192px;
    position: absolute;
    right: -1em;
    top: calc(-8em + 40px);
    animation: rotation 60s infinite linear;
    svg {
        fill: #FFFFFF;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media (min-width: 768px) {
        right: 2em;
    }
`;




